import * as React from "react";
import { graphql } from "gatsby";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import { SEO } from "../../components/SEO";
import FullWidthImage from "../../components/FullWidthImage";
import ButtonLink from "../../components/ButtonLink";
import PhoneIcon from "../../components/icons/phone-icon";
import FaxIcon from "../../components/icons/fax-icon";
import EmailIcon from "../../components/icons/email-icon";
import PinIcon from "../../components/icons/pin-icon";
import { Link } from "gatsby";

import LinkWithIcon from "../../components/LinkWithIcon";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    const { data } = this.props;
    const heroImage = data.heroImage.childImageSharp.gatsbyImageData;

    return (
      <Layout>
        <FullWidthImage
          img={heroImage}
          title="Contact Us"
          // subheading="Get a hold of us."
        />

        <section className="section is-medium">
          <div className="container"></div>
          <div className="container">
            <div className="columns">
              <div className="column">
                <h2 className="title is-size-3-mobile is-size-2-tablet">
                  Send us a Message
                </h2>
                <form
                  name="contact"
                  method="post"
                  action="/contact/thanks/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                  className="has-text-white"
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="contact" />
                  <div hidden>
                    <label>
                      Don’t fill this out:{" "}
                      <input name="bot-field" onChange={this.handleChange} />
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={"name"}>
                      Name
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={"text"}
                        name={"name"}
                        onChange={this.handleChange}
                        id={"name"}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={"email"}>
                      Email
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={"email"}
                        name={"email"}
                        onChange={this.handleChange}
                        id={"email"}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={"message"}>
                      Message
                    </label>
                    <div className="control">
                      <textarea
                        className="textarea"
                        name={"message"}
                        onChange={this.handleChange}
                        id={"message"}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <ButtonLink type="submit">Send</ButtonLink>
                  </div>
                </form>
              </div>
              <div className="column" style={{ display: "flex" }}>
                <div
                  className="box has-background-black-bis"
                  style={{ width: "100%" }}
                >
                  <h2 className="title is-size-3-mobile is-size-2-tablet is-size-1-desktop">
                    Visit Our
                    <span className="has-text-primary"> Locations</span>
                  </h2>
                  <div className="columns has-text-left has-text-white is-small">
                    <div className="column is-4">
                      <h3 className="location-title">Algona</h3>
                      <p class="location-attorney">Thomas Lipps</p>
                      <p class="location-address">
                        6 East State Street
                        <br />
                        Algona, IA 50511
                      </p>
                      <p class="location-email">
                        <a href="mailto:algona@sjlawoffice.com">
                          algona@sjlawoffice.com
                        </a>
                      </p>
                      <p class="location-phone">
                        <b>Phone:</b>{" "}
                        <a href="tel:(515) 295-9494">(515) 295-9494</a>
                      </p>
                      <p>
                        <b>Fax:</b>{" "}
                        <a href="tel:(515) 295-9493">(515) 295-9493</a>
                      </p>
                    </div>
                    <div className="column is-4">
                      <h3 className="location-title">Britt</h3>
                      <p class="location-attorney">
                        David Siegrist & Brian Jones
                      </p>
                      <p class="location-address">
                        94 Main Avenue North
                        <br />
                        Britt, IA 50423
                      </p>
                      <p class="location-email">
                        <a href="mailto:britt@sjlawoffice.com">
                          britt@sjlawoffice.com
                        </a>
                      </p>
                      <p class="location-phone">
                        <b>Phone:</b>{" "}
                        <a href="tel:(641) 843-4451">(641) 843-4451</a>
                      </p>
                      <p>
                        <b>Fax:</b>{" "}
                        <a href="tel:(641) 843-3730">(641) 843-3730</a>
                      </p>
                    </div>
                    <div className="column is-4">
                      <h3 className="location-title">Forest City</h3>
                      <p class="location-attorney">Steven Bakke</p>
                      <p class="location-address">
                        150 N Clark St
                        <br />
                        Forest City, IA 50436
                      </p>
                      <p class="location-email">
                        <a href="mailto:forestcity@sjlawoffice.com">
                          forestcity@sjlawoffice.com
                        </a>
                      </p>
                      <p class="location-phone">
                        <b>Phone:</b>{" "}
                        <a href="tel:(641) 585-2530">(641) 585-2530</a>
                      </p>
                      <p>
                        <b>Fax:</b>{" "}
                        <a href="tel:(641) 585-2023">(641) 585-2023</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ContactPage {
    heroImage: file(relativePath: { eq: "contact-jumbotron.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Contact"
    description="SJLB Law Firm, located in Britt, IA, is ready to get to fight for you. Contact us today to get the legal care you deserve."
  />
);
